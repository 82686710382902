:root {
    --whitebase: #ffffff;

    --black-100: #e6e6e6;
    --black-150: #cccccc;
    --black-200: #939393;
    --black-300: #666666;
    --black-base: #212121;
    --black-700: #830d0b;

    --primary-700: #0f4880;
    --primary-600: #125699;
    --primary-500: #1873cc;
    --primary-400-base: #1e90ff;
    --primary-450: #1ab9f2;
    --primary-300: #62b1ff;
    --primary-200: #a5d3ff;
    --primary-100: #e9f4ff;
    --tertiary-050: #ebebeb;

    --secondary-700: #330a1f;
    --secondary-600: #66153e;
    --secondary-400-base: #387888;
    --secondary-400-base-error: #9b3d3b;
    --secondary-500: #b3246d;
    --secondary-300: #ff71b9;
    --secondary-200: #ffaed7;
    --secondary-100: #ffebf5;

    --tertiary-400-base: #5540fb;
    --tertiary-300: #8879fc;
    --tertiary-200: #bbb3fd;
    --tertiary-100: #ccc6fe;
    --tertiary-500: #464646;
    --tertiary-600: #221a64;
    --tertiary-700: #110d32;

    --success-400-base: #69923a;

    --warning-50: #ffeeba;
    --warning-100: #fff1d7;
    --warning-200: #ffe4af;
    --warning-300: #2f4858;
    --warning-400-base: #ff8a38;
    --warning-400-base-2: #856404;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #df1642;
    --error-500: #9c0f2e;
    --error-600: #59091a;
    --error-700: #2d040d;

    --klassapp-300: #f26948;
    --klassapp-250: #ff6a47;
    --klassapp-200: #f4876c;
    --klassapp-100: #f9c3b5;
    --klassapp-10: #fce7e1;
    --klassapp-1: #fef9f7;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --whitebase-20: rgba(255, 255, 255, 0.2);
    --black-30: rgba(0, 0, 0, 0.3);
    --black-90: rgba(235, 235, 235, 0.9);
    --whitebase-30: rgba(255, 255, 255, 0.3);
    --whitebase-90: rgba(255, 255, 255, 0.9);
}

body,
html {
    margin: 0;
    font-family: "Roboto", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: 100px;
}

a {
    outline: none;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    justify-content: center;
    align-items: center;
}
